* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100vh;
  font-family: 'Poppins';
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

#drawing-canvas {
  background-color: #000000;
  /* z-index: 3000; */
  cursor: crosshair;
  touch-action: none;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: scale(0.2) translate(calc(100%* 2.3), calc(100%* -2.3));
  display: flex !important;
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  visibility: hidden !important;
  z-index: -1 !important;
}

#cnvs {
  display: flex !important;
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  visibility: hidden !important;
  z-index: -1 !important;
}

#debug-canvas {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 555;
  background-color: red;
}

#canvaswrapper {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 124px;
  height: 124px;
  display: flex !important;
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  visibility: hidden !important;
  z-index: -1 !important;
}

#interface {
  position: fixed;
  right: 16px;
  top: 16px;
}

.canvas-container {
  display: flex !important;
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  visibility: hidden !important;
  z-index: -1 !important;
}

#zone_joystick {
  position: fixed;
  right: 60px;
  bottom: 110px;
  z-index: 555;
  width: 60px;
  height: 60px;
  user-select: none;
}

/* .canvas-container canvas{
    background-color: #000000!important;
    z-index: 3000!important;
    cursor: crosshair!important;
    touch-action: none!important;
    position: absolute!important;
    right: 50%!important;
    top: 50%!important;
    transform: scale(0.2) translate(calc(100%* 2.3), calc(100%* -2.3))!important;
}
.canvas-container {
    position: absolute!important;
    top: 0px!important;
    right: 0px!important;
    width: 512px!important;
    height: 512px!important;
} */

#ver {
  position: fixed;
  bottom: 90px;
  left: 16px;
  color: white;
  z-index: 556;
}

.nipple .back {
  background: transparent !important;
  border: 2px solid white !important;
  box-sizing: border-box !important;
}




/* Variables */
:root {
  --background: #f0f0f0;
  --white: #fff;
  --black: #1a1a1a;
  --primary: #01a3e3;
  --thickness: 1.8rem;
}

/* Box sizing */
* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

/* Rotation control */
.rotation-control {
  background: var(--black);
  padding: 1.5rem 2.5rem;
  border-radius: 1rem;
  user-select: none;
}

.rotation-control .label {
  color: var(--white);
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-family: 'Lato';
}

.rotation-control .circle {
  width: 120px;
  height: 120px;
  position: relative;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rotation-control .circle .circle-base,
.rotation-control .circle .circle-indicator {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rotation-control .circle .circle-base {
  border: var(--thickness) solid rgba(var(--white), 0.1);
}

.rotation-control .circle .circle-indicator {
  cursor: grab;
}

.rotation-control .circle .circle-indicator::after {
  content: "";
  width: var(--thickness);
  height: var(--thickness);
  /* background: var(--primary); */
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.rotation-control .value {
  color: var(--white);
  font-size: 2.25rem;
  font-family: 'Lato';
  position: relative;
  z-index: 10;
  width: auto;
  border: 0;
  background: none;
  text-align: center;
}

.rotation-control .value:focus {
  outline: 0;
}

.rotation-control .controls {
  border-top: 0.125rem solid rgba(var(--white), 0.1);
  margin-top: 1.5rem;
  padding-top: 1rem;
  position: relative;
}

.rotation-control .controls::before,
.rotation-control .controls::after {
  content: "";
  display: table;
  clear: both;
}

.rotation-control .controls button {
  background: none;
  border: 0;
  cursor: pointer;
  position: relative;
  color: var(--white);
  padding: 0;
}

.rotation-control .controls button:focus {
  outline: 0;
}

.rotation-control .controls button svg {
  width: 1rem;
}

.rotation-control .controls button.rotate-left,
.rotation-control .controls button.rotate-right {
  position: absolute;
}

.rotation-control .controls button.rotate-left span,
.rotation-control .controls button.rotate-right span {
  position: absolute;
  font-size: 0.95rem;
  top: 0.7rem;
}

.rotation-control .controls button.rotate-left {
  float: left;
  margin-left: 0.55rem;
}

.rotation-control .controls button.rotate-left span {
  right: 0.55rem;
}

.rotation-control .controls button.rotate-right {
  float: right;
  margin-right: 0.55rem;
}

.rotation-control .controls button.rotate-right span {
  left: 0.55rem;
}

.rotation-control .controls button.reset {
  position: absolute;
  left: 0;
  right: 0;
  top: 0.9375rem;
  margin: auto;
  width: 1.7rem;
  height: 1.7rem;
}

.rotation-control .controls button.reset svg {
  width: 1.7rem;
}

/* Social Media & centering */
body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  min-height: 100vh;
  background: var(--background);
  user-select: none; 
  -webkit-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
}

.social-media {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  opacity: 0.85;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 1.25rem;
}

.social-media a {
  display: inline-block;
  color: var(--white);
}

.social-media a:not(:last-of-type) {
  margin-right: 0.5rem;
}

.social-media:hover {
  opacity: 1;
}

.rotation-control {
  padding: 0 !important;
  overflow: hidden;
  position: absolute;
  bottom: 80px;
  right: 30px;
  background-color: transparent !important;
  user-select: none;
}

.rotation-control .circle .circle-indicator::after {
  background-image: url('https://s3.eu-central-1.amazonaws.com/editor.customitem.app/rotate.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 1;
}

#zone_joystick .front {
  background-image: url('https://s3.eu-central-1.amazonaws.com/editor.customitem.app/translate.png') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  opacity: 1 !important;
}

#zone_joystick .nipple {
  opacity: 1 !important;
}

#zone_joystick .back {
  display: none !important;
}

.rotation-control .circle .circle-base {
  position: absolute;
  top: 12px;
  left: 12px;
  display: block;
  width: 96px;
  height: 96px;
  background: transparent;
  opacity: 1;
  border: 2px solid white;
  border-radius: 100%;
}

/* Loader style */
#loader-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 11111;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000e3;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

#loader {
  color: #ccc;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loader::after,
#loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: scale(0.5) translate(0, 0);
  background-color: #d9d9d9;
  border-radius: 50%;
  animation: animloader 1s infinite ease-in-out;
}

#loader::before {
  background-color: #ffffff;
  transform: scale(0.5) translate(-48px, -48px);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animloader {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}

.scale-control {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 178px;
  right: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.scale-control__circle {
  background-image: url('https://s3.eu-central-1.amazonaws.com/editor.customitem.app/scale.png') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  opacity: 1 !important;
  width: 30px;
  height: 30px;
  user-select: none;
}

/* END Loader style */